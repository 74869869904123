
import { defineComponent, ref } from "vue"
import store from "@/store"

export default defineComponent({
  name: "settings",
  emits: ["throwError"],
  data() {
    const accountNum = store.getters.activeAccountInfo.id
    return {
      accountNum,
      loading: ref(false)
    }
  },
});
